import React from 'react'
import styled from 'styled-components'
import IntersectionVisible from 'react-intersection-visible'

const Svg = styled.svg`
  &.not-visible {
    display: none;
  }

  &.visible {
    .pen-drawable {
      stroke-dasharray: 1000;
      stroke-dashoffset: 1000;
      animation: dash 1s linear forwards;
      animation-delay: 0.5s;

      &.delayed {
        animation: dash 2s linear forwards;
        animation-delay: 1s;
      }

      &.inverted {
        animation: dash-inverted 1s linear forwards;
      }
    }

    @keyframes dash {
      to {
        stroke-dashoffset: 0;
      }
    }

    @keyframes dash-inverted {
      from {
        stroke-dashoffset: -1000;
      }
      to {
        stroke-dashoffset: 0;
      }
    }
  }
`

export default class Icon extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      visible: false,
    }
  }

  render() {
    const { visible } = this.state
    return (
      <IntersectionVisible
        onHide={() => this.setState({ visible: false })}
        onShow={() => this.setState({ visible: true })}
        options={{ rootMargin: '-30px' }}
      >
        <Svg className={visible ? "visible" : "not-visible"} width="158" height="170" viewBox="0 0 158 170" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path className="pen-drawable inverted" d="M156.952 28.8756L153.817 28.6313C118.072 25.8318 81.2195 2.42517 80.8524 2.18764L79 1L77.1476 2.18764C76.7805 2.42517 39.928 25.8318 4.18348 28.6313L1.04788 28.8756L1.00073 32.0415C0.987257 32.9373 0.522476 121.949 77.2352 167.941L79 169L80.7648 167.941C157.478 121.949 157.013 32.9373 156.999 32.0415L156.952 28.8756Z" stroke="#00A9A5" strokeWidth="1.5"/>
          <circle className="pen-drawable delayed" cx="78.25" cy="49.375" r="17.625" stroke="white" strokeWidth="1.5"/>
          <path className="pen-drawable delayed" d="M114.242 114.25H42.2577C42.6569 94.714 58.6184 79 78.25 79C97.8816 79 113.843 94.714 114.242 114.25Z" stroke="white" strokeWidth="1.5"/>
        </Svg>
      </IntersectionVisible>
    )
  }
}
