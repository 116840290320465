import React from 'react'
import Link from 'gatsby-link'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import Breakpoint from 'react-socks'

import InputCoinSelector from '../../InputCoinSelector'
import Container from '../../Container'
import BlockRevealAnimation from '../../BlockRevealAnimation'
import * as Colors from '../../../utils/colors'
import SwitchCoinsButton from '../../Buttons/SwitchCoinsButton'
import BGLogoWhite from './button-logo.svg'
import BGLogoBlack from './button-logo-black.svg'
import Arrow from './Arrow.js'

const Background = styled.div`
  width: 100%;

  background: ${Colors.BLACK};
  @media (min-width: 992px) {
    background: linear-gradient(
      90deg,
      ${Colors.BLACK} 0%,
      ${Colors.BLACK} ${props => props.width},
      ${props => (props.theme === 'green' ? Colors.GREEN : Colors.LIGHT_GREY)}
        ${props => props.width},
      ${props => (props.theme === 'green' ? Colors.GREEN : Colors.LIGHT_GREY)}
        100%
    );
  }

  @media (min-width: 1350px) {
    background: linear-gradient(
      to right,
      ${Colors.BLACK} 0%,
      ${Colors.BLACK} ${props => props.width},
      ${props => (props.theme === 'green' ? Colors.GREEN : Colors.BACKGROUND)}
        ${props => props.width},
      ${props => (props.theme === 'green' ? Colors.GREEN : Colors.BACKGROUND)}
        95%,
      ${props => (props.theme === 'green' ? Colors.BACKGROUND : Colors.WHITE)}
        95%,
      ${props => (props.theme === 'green' ? Colors.BACKGROUND : Colors.WHITE)}
        100%
    );
  }
`

const Wrapper = styled(Container)`
  display: flex;
  align-items: center;

  @media (max-width: 992px) {
    flex-direction: column;
  }
`

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.5rem 0.5rem 1.5rem 0;

  @media (max-width: 992px) {
    margin: 1rem 0;
    width: 100%;
  }
`

const Label = styled.span`
  color: ${Colors.GREY};
  line-height: 20px;
  font-size: 16px;
`

const ButtonWrapper = styled.div`
  display: flex;
  transition: opacity 0.5s ease;
  transition-delay: 1.8s;

  &.not-visible {
    opacity: 0;
  }

  &.visible {
    opacity: 1;
  }
`

const ConvertButton = styled(Link)`
  margin-left: 2rem;
  width: 144px;
  height: 60px;
  border-radius: 0;
  padding-top: 20px;
  padding-bottom: 16px;
  background: ${props =>
    props.theme === 'green' ? Colors.WHITE : Colors.GREEN};
  color: ${props =>
    props.theme === 'green' ? Colors.BLACK : Colors.LIGHT_GREY};
  outline-color: ${Colors.DARK_GREEN};
  text-decoration: none;
  text-align: center;
  font-size: 20px;
  background-position-x: 142%;
  transition-property: background-position-x, width, margin-top, padding-right, box-shadow, height;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  font-weight: bold;
  box-shadow: 0px 6px 0px ${props =>
    props.theme === 'green' ? Colors.DARK_GREY : Colors.DARK_GREEN};

  &:hover, &:active, &:focus {
    box-shadow: none;
    background-image: url('${props =>
      props.theme === 'green' ? BGLogoBlack : BGLogoWhite}');
    background-repeat: no-repeat;
    background-position-x: 102%;
    background-position-y: center;
    width: 174px;
    padding-right: 30px;
    margin-top: 6px;
    height: 66px;
  }

  @media (max-width: 992px) {
    margin-left: 0;
    top: 1rem;
    position: relative;
    align-self: flex-end;
  }
`

const NoAccountWrapper = styled(Breakpoint)`
  align-self: flex-end;
  position: relative;
  padding-top: 1rem;

  @media (min-width: 992px) {
    margin-right: 10%;
  }
  @media (min-width: 1024px) {
    margin-right: 13%;
  }
  @media (min-width: 1050px) {
    margin-right: 15%;
  }
  @media (min-width: 1100px) {
    margin-right: 20%;
  }
  @media (min-width: 1150px) {
    margin-right: 23%;
  }
  @media (min-width: 1200px) {
    margin-right: 26%;
  }
  @media (min-width: 1350px) {
    margin-right: 28%;
  }
  @media (min-width: 1500px) {
    margin-right: 30%;
  }
  @media (min-width: 1650px) {
    margin-right: 32%;
  }
  @media (min-width: 1800px) {
    margin-right: 34%;
  }
`

const NoAccountRequiredText = styled.span`
  font-size: 20px;
  line-height: 28px;
  font-weight: normal;
`

export default class Coins extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      width: '66%',
      buttonVisible: false,
    }
  }

  // When mounting, find the current screen width
  componentDidMount() {
    this.setState({ width: this.getBackgroundWidth() })
    setTimeout(() => this.setState({ buttonVisible: true }))
  }

  getBackgroundWidth = () => {
    const width = typeof window !== 'undefined' ? window.innerWidth : 0
    switch (true) {
      case width <= 992:
        return '90%'
      case width <= 1024:
        return '87%'
      case width <= 1050:
        return '85%'
      case width <= 1100:
        return '80%'
      case width <= 1150:
        return '77%'
      case width <= 1200:
        return '74%'
      case width <= 1350:
        return '72%'
      case width <= 1500:
        return '70%'
      case width <= 1650:
        return '68%'
      default:
        return '66%'
    }
  }

  render() {
    const {
      originCoin,
      setOriginCoin,
      changeOriginValue,
      targetCoin,
      setTargetCoin,
      changeTargetValue,
      theme = 'grey',
      showNoAccountRequired = false,
    } = this.props
    const { width, buttonVisible } = this.state
    return (
      <>
        <BlockRevealAnimation
          style={{ width: '100%' }}
          color={Colors.BLACK}
          maxWidth={width}
        >
          <Background theme={theme} width={width}>
            <Wrapper>
              <InputWrapper>
                <Label>
                  <FormattedMessage id="HOME.COIN_SELECTOR_SEND" />
                </Label>
                <InputCoinSelector
                  coin={originCoin}
                  setCoin={setOriginCoin}
                  setValue={changeOriginValue}
                />
              </InputWrapper>

              <SwitchCoinsButton />
              <InputWrapper>
                <Label>
                  <FormattedMessage id="HOME.COIN_SELECTOR_RECEIVE" />
                </Label>
                <InputCoinSelector
                  coin={targetCoin}
                  setCoin={setTargetCoin}
                  setValue={changeTargetValue}
                  symbol={'\u2248'}
                />
              </InputWrapper>

              <ButtonWrapper
                className={buttonVisible ? 'visible' : 'not-visible'}
              >
                <ConvertButton to="/exchange/" theme={theme}>
                  <FormattedMessage id="HOME.COIN_SELECTOR_GO_STABLE" />
                </ConvertButton>
              </ButtonWrapper>
            </Wrapper>
          </Background>
        </BlockRevealAnimation>
        {showNoAccountRequired && (
          <NoAccountWrapper large up>
            <BlockRevealAnimation
              style={{ width: '100%' }}
              color={Colors.BACKGROUND}
              delay={0.3}
            >
              <NoAccountRequiredText>
                <FormattedMessage id="HOME.NO_ACCOUNT_REQUIRED" />
              </NoAccountRequiredText>
              <Arrow />
            </BlockRevealAnimation>
          </NoAccountWrapper>
        )}
      </>
    )
  }
}
