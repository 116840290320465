import React from 'react'
import styled from 'styled-components'

import * as Colors from '../../../../utils/colors'
import CoinLeftContent from '../CoinLeftContent'
import CoinRightContent from '../CoinRightContent'

const CoinBox = styled.div`
  flex: 1;
  display: flex;
  margin-bottom: 2rem;
  border-top: 1px solid ${Colors.GREY};

  @media (max-width: 992px) {
    flex-direction: column;
    margin-bottom: 0;
  }
`

export default (props) => (
  <CoinBox>
    <CoinLeftContent {...props} />
    <CoinRightContent {...props} />
  </CoinBox>
)
