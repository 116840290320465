import React from 'react'
import styled from 'styled-components'
import { Breakpoint } from 'react-socks'

import Container from '../../Container'
import * as Colors from '../../../utils/colors'
import FastSVG from './Illustrations/Fast.js'
import PrivateSVG from './Illustrations/Private.js'
import AskUsArrow from './Illustrations/ask-us-arrow.svg'
import SingleFeature from './SingleFeature'

const Background = styled.div`
  background: ${Colors.BLACK};

  @media (min-width: 1350px) {
    background: linear-gradient(90deg, ${Colors.WHITE} 0%, ${Colors.WHITE} 3%, ${Colors.BLACK} 3%, ${Colors.BLACK} 100%);
  }
`

const Wrapper = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  padding-top: 10rem;
  padding-bottom: 6rem;

  @media (max-width: 992px) {
    flex-direction: column;
    padding-bottom: 0;
    padding-top: 4rem;
  }
`

const Illustration = styled(Breakpoint)`
  flex: 0 50%;
  display: flex;
  justify-content: center;
  background-origin: content-box;
  background-position-x: 50%;
  background-position-y: top;
  background-repeat: no-repeat;
  background-size: initial;
  margin-top: 1.7rem;
  padding-right: 4rem;

  @media (max-width: 992px) {
    min-height: 12rem;
    width: 100%;
    text-align: center;
    margin: 0;
    margin-bottom: 4rem;
    padding-right: 0;
  }
`

export default () => (
  <Background>
    <Wrapper>
      <Illustration xsmall up><FastSVG /></Illustration>

      <SingleFeature
        title="HOME.FEATURES_FAST"
        description="HOME.FEATURES_FAST_DESCRIPTION"
        items={["HOME.FEATURES_FAST_LIST_ITEM_1", "HOME.FEATURES_FAST_LIST_ITEM_2"]}
        marginLeft
      />

      <SingleFeature
        title="HOME.FEATURES_RELIABLE"
        description="HOME.FEATURES_RELIABLE_DESCRIPTION"
        items={["HOME.FEATURES_RELIABLE_LIST_ITEM_1", "HOME.FEATURES_RELIABLE_LIST_ITEM_2", "HOME.FEATURES_RELIABLE_LIST_ITEM_3"]}
      />

      <SingleFeature
        title="HOME.FEATURES_PRIVACY"
        description="HOME.FEATURES_PRIVACY_DESCRIPTION"
        items={["HOME.FEATURES_PRIVACY_LIST_ITEM_1", "HOME.FEATURES_PRIVACY_LIST_ITEM_2"]}
        marginLeft
      />

      <SingleFeature
        title="HOME.FEATURES_FAIR"
        description="HOME.FEATURES_FAIR_DESCRIPTION"
        items={["HOME.FEATURES_FAIR_LIST_ITEM_1", "HOME.FEATURES_FAIR_LIST_ITEM_2"]}
      />

      <SingleFeature
        title="HOME.FEATURES_LIQUID"
        description="HOME.FEATURES_LIQUID_DESCRIPTION"
        items={["HOME.FEATURES_LIQUID_LIST_ITEM_1", "HOME.FEATURES_LIQUID_LIST_ITEM_2"]}
        marginLeft
      />

      <Illustration large up><PrivateSVG /></Illustration>

      <SingleFeature
        title="HOME.FEATURES_TRANSPARENT"
        description="HOME.FEATURES_TRANSPARENT_DESCRIPTION"
        items={["HOME.FEATURES_TRANSPARENT_LIST_ITEM_1"]}
        marginLeft
        finalContent={<img style={{ marginLeft: '0.5rem' }} src={AskUsArrow} alt="Arrow down" />}
      />

      <Illustration medium down><PrivateSVG /></Illustration>
    </Wrapper>
  </Background>
)
