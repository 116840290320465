import React from 'react'
import styled from 'styled-components'

import BlackHeader from '../../Headers/BlackHeader'
import Background from '../../Backgrounds/HalfWhiteHalfGrey'
import Container from '../../Container'
import MoreComingSoon from '../MoreComingSoon'
import Coin from './Coin'
import TUSDLogo from './Logos/tusd.svg'
import USDCLogo from './Logos/usdc.svg'
import PAXLogo from './Logos/pax.svg'
import USDTLogo from './Logos/usdt.png'

const Wrapper = styled(Container)`
  display: flex;
  flex-direction: column;
`

export default ({ coins }) => {
  const tusd = coins.find(c => c.key === 'TUSD') || {}
  const usdc = coins.find(c => c.key === 'USDC') || {}
  const pax = coins.find(c => c.key === 'PAX') || {}
  const usdt = coins.find(c => c.key === 'USDT') || {}
  return (
    <Background>
      <Wrapper>
        <BlackHeader title="HOME.OUR_COINS" />
        <Coin logo={TUSDLogo} coin={tusd} />
        <Coin logo={USDCLogo} coin={usdc} />
        <Coin logo={PAXLogo} coin={pax} />
        <Coin logo={USDTLogo} coin={usdt} />
        <MoreComingSoon />
      </Wrapper>
    </Background>
  )
}
