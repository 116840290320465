import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import Breakdown from 'react-socks'

import * as Colors from '../../../utils/colors'
import HomeCoins from '../Coins'
import Logo from '../../Logo'
import Container from '../../Container'

const BgContainer = styled.div`
  background: ${props =>
    props.theme === 'green' ? Colors.GREEN : Colors.BACKGROUND};

  @media (min-width: 1350px) {
    background: linear-gradient(
      90deg,
      ${props => (props.theme === 'green' ? Colors.GREEN : Colors.BACKGROUND)}
        95%,
      ${props => (props.theme === 'green' ? Colors.BACKGROUND : Colors.WHITE)}
        5%
    );
  }
`

const StageWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 7rem 0;

  @media (max-width: 992px) {
    padding: 0;
  }
`

const StageContainer = styled(Container)`
  padding: 0 1rem;
`

const StageCTA = styled.h1`
  color: ${Colors.BLACK};
  line-height: 56px;
  font-size: 56px;
  padding: 3.5rem 0;
  margin-bottom: 0;

  @media (max-width: 992px) {
    font-size: 40px;
  }
`

const StageSecText = styled.h2`
  width: 50%;
  color: ${props => (props.theme === 'green' ? Colors.BLACK : Colors.GREY)};
  line-height: 28px;
  font-size: 20px;
  font-weight: normal;
  padding-bottom: 3.5rem;
  margin-bottom: 0;

  @media (max-width: 992px) {
    width: 100%;
    line-height: 20px;
    font-size: 16px;
  }
`

const AnimatedCTA = props => (
  <StageCTA {...props}>
    <FormattedMessage
      id={
        props.theme === 'green'
          ? 'HOME.GO_STABLE_NOW'
          : 'HOME.STABILIZE_ASSETS_IN_SECONDS'
      }
    />
  </StageCTA>
)

const AnimatedSecText = props => (
  <StageSecText {...props}>
    <FormattedMessage id="HOME.SWAP_BETWEEN_ASSETS" />
  </StageSecText>
)

export default props => (
  <BgContainer {...props}>
    <StageWrapper>
      <StageContainer>
        {props.theme === 'green' ? null : (
          <Breakdown large up>
            <Logo height={31.38} />
          </Breakdown>
        )}
        <AnimatedCTA {...props} />
        <AnimatedSecText {...props} />
      </StageContainer>
      <HomeCoins {...props} />
    </StageWrapper>
  </BgContainer>
)
