import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import AOS from 'aos'
import 'aos/dist/aos.css'

// Redux actions
import {
  initialCoinSet,
  setOriginCoin,
  setTargetCoin,
  changeOriginValue,
  changeTargetValue,
} from '../state/actions/coinValues'

// Presentational components
import About from '../components/Home/About'
import MainStage from '../components/Home/MainStage'
import HowItWorks from '../components/Home/HowItWorks'
import Features from '../components/Home/Features'
import CoinInfo from '../components/Home/CoinInfo'
import Navbar from '../components/Navbar'
import Layout from '../components/Layout'
import Footer from '../components/Footer'
import SEO from '../components/SEO'
import HalfWhiteHalfGrey from '../components/Backgrounds/HalfWhiteHalfGrey'

import * as Colors from '../utils/colors'

const BgWrapper = styled.div`
  background: ${Colors.LIGHT_GREY};
`

class IndexPage extends React.Component {
  componentDidMount() {
    this.props.initialCoinSet()
    AOS.init()
  }

  render() {
    return (
      <Layout>
        <SEO
          title="GoStable - Stabilize your digital assets within seconds"
          keywords={[
            `btc`,
            `bitcoin`,
            `stablecoin`,
            `stable`,
            `convert`,
            `trade`,
          ]}
        />
        <Navbar isHome={true} />
        <MainStage {...this.props} showNoAccountRequired />
        <HowItWorks />
        <Features />
        <CoinInfo coins={this.props.coins.filter(c => c.isStable)} />
        <About />
        <BgWrapper>
          <MainStage {...this.props} theme="green" />
        </BgWrapper>
        <HalfWhiteHalfGrey>
          <Footer />
        </HalfWhiteHalfGrey>
      </Layout>
    )
  }
}

const mapStateToProps = state => {
  return {
    originCoin: state.originCoin,
    targetCoin: state.targetCoin,
    coins: state.coins,
  }
}

const mapDispatchToProps = dispatch => ({
  initialCoinSet: () => dispatch(initialCoinSet()),
  setOriginCoin: coin => dispatch(setOriginCoin(coin)),
  setTargetCoin: coin => dispatch(setTargetCoin(coin)),
  changeOriginValue: value => dispatch(changeOriginValue(value)),
  changeTargetValue: value => dispatch(changeTargetValue(value)),
})

const ConnectedIndexPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(IndexPage)

export default ConnectedIndexPage
