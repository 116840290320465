import React from 'react'
import PropTypes from 'prop-types'

import './BlockRevealAnimation.css'

const BlockRevealAnimation = ({
  delay,
  duration,
  className,
  children,
  color,
  style = {},
  maxWidth = '100%',
}) => (
  <div className={`block-reveal-animation ${className}`} style={style}>
    <div
      className="block-reveal-animation__element"
      style={{
        animationDelay: `${delay + duration / 2}s`,
      }}
    >
      {children}
    </div>
    <div
      className="block-reveal-animation__block"
      style={{
        animationDelay: `${delay}s`,
        animationDuration: `${duration}s`,
        backgroundColor: color,
        maxWidth,
      }}
    />
  </div>
)

BlockRevealAnimation.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  className: PropTypes.string,
  delay: PropTypes.number,
  duration: PropTypes.number,
}

BlockRevealAnimation.defaultProps = {
  className: '',
  delay: 0.0,
  duration: 0.0,
  color: '#000',
}

export default BlockRevealAnimation
