import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

import * as Colors from '../../../utils/colors'
import Container from '../../Container'
import BGLogo from './bg.svg'
import BGLogoWhite from './bg-white.svg'

const Background = styled.div`
  background: ${Colors.LIGHT_GREY};
  background-image: url('${BGLogoWhite}');
  background-repeat: no-repeat;
  background-position-y: 35%;
  background-size: contain;

  @media (min-width: 992px) {
    background: linear-gradient(90deg, ${Colors.WHITE} 50%, ${Colors.LIGHT_GREY} 50%);
    background-image:: none;
  }
`

const Wrapper = styled(Container)`
  display: flex;
  padding: 10.5rem 1rem;

  @media (max-width: 992px) {
    padding: 8rem 1rem;
  }
`

const LeftWrapper = styled.div`
  flex: 1;

  @media (max-width: 992px) {
    flex: 0;
  }
`

const RightWrapper = styled.div`
  flex: 1;
  margin-left: 8rem;

  @media (max-width: 992px) {
    margin-left: 0;
  }
`

const AboutHeader = styled.h3`
  line-height: 56px;
  font-size: 40px;
  color: ${Colors.BLACK};
  margin-bottom: 4rem;

  @media (max-width: 992px) {
    margin-bottom: 2rem;
  }
`

const AboutText = styled.span`
  line-height: 28px;
  font-size: 20px;
  color: ${Colors.GREY};
`

export default () => {
  return (
    <Background>
      <Wrapper>
        <LeftWrapper>
          <img src={BGLogo} alt="Go Stable" />
        </LeftWrapper>

        <RightWrapper>
          <AboutHeader><FormattedMessage id="HOME.ABOUT" /></AboutHeader>
          <AboutText><FormattedMessage id="HOME.ABOUT_TEXT" /></AboutText>
        </RightWrapper>
      </Wrapper>
    </Background>
  )
}
