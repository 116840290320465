import React from 'react'
import styled from 'styled-components'
import IntersectionVisible from 'react-intersection-visible'

const Svg = styled.svg`
  &.not-visible {
    display: none;
  }

  &.visible {
    .pen-drawable {
      stroke-dasharray: 1000;
      stroke-dashoffset: 1000;
      animation: dash 1s linear forwards;
      animation-delay: 0.8s;

      &.delayed {
        stroke-dasharray: 200;
        stroke-dashoffset: 200;
        animation-delay: 1.2s;
      }
    }

    @keyframes dash {
      to {
        stroke-dashoffset: 0;
      }
    }
  }
`

const Wrapper = styled.div`
  position: absolute;
  top: -35px;
  right: -100px;
`

export default class Icon extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      visible: false,
    }
  }

  render() {
    const { visible } = this.state
    return (
      <Wrapper>
        <IntersectionVisible
          onHide={() => this.setState({ visible: false })}
          onShow={() => this.setState({ visible: true })}
          options={{ rootMargin: '-30px' }}
        >
          <Svg
            className={visible ? 'visible' : 'not-visible'}
            width="84"
            height="81"
            viewBox="0 0 84 81"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="pen-drawable delayed"
              d="M68.3796 7.63387L82.3477 9.84619"
              stroke="#00A9A5"
              strokeWidth="1.5"
              strokeLinecap="square"
            />
            <path
              className="pen-drawable delayed"
              d="M68.3803 7.63403L66.168 21.6021"
              stroke="#00A9A5"
              strokeWidth="1.5"
              strokeLinecap="square"
            />
            <path
              className="pen-drawable"
              d="M1.04297 56C7.78375 70.1897 22.2468 80 39.0012 80C62.1971 80 81.0012 61.196 81.0012 38C81.0012 26.2479 76.1744 15.6231 68.3951 8"
              stroke="#00A9A5"
              strokeWidth="1.5"
            />
          </Svg>
        </IntersectionVisible>
      </Wrapper>
    )
  }
}
