import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import IntersectionVisible from 'react-intersection-visible'

import * as Colors from '../../../../utils/colors'
import BlockRevealAnimation from '../../../BlockRevealAnimation'

const FeatureBox = styled.div`
  flex: 1;
  display: flex;
  margin-bottom: 2rem;
  border-top: 1px solid ${Colors.GREY};
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 992px) {
    margin-bottom: 4rem;
  }
`

const FeatureTitle = styled.h4`
  line-height: 40px;
  font-size: 28px;
  font-weight: normal;
  color: ${Colors.GREY};
  margin: 2rem 0;

  @media (max-width: 992px) {
    line-height: 28px;
    font-size: 24px;
  }

  &.not-visible {
    opacity: 0;
    visibility: hidden;
  }

  &.visible {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition-delay: 0.4s;
  }

  &.delay-1 {
    transition-delay: 1s;
  }

  &.delay-2 {
    transition-delay: 1.6s;
  }
`

export default class Step extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      visible: false,
    }
  }

  render() {
    const { visible } = this.state
    const { text, illustration, step, ...rest } = this.props
    return (
      <IntersectionVisible
        onHide={() => this.setState({ visible: false })}
        onShow={() => this.setState({ visible: true })}
      >
        <BlockRevealAnimation color="white">
          <FeatureBox {...rest}>
            <FeatureTitle className={visible}>
              <FormattedMessage id={text} />
            </FeatureTitle>
            {illustration}
          </FeatureBox>
        </BlockRevealAnimation>
      </IntersectionVisible>
    )
  }
}
