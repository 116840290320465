import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

const Header = styled.h2`
  line-height: 56px;
  font-size: 40px;
  margin-top: 10rem;
  margin-bottom: 5rem;

  @media (max-width: 992px) {
    margin-top: 6rem;
    margin-bottom: 3rem;
    line-height: 40px;
    font-size: 28px;
  }
`

export default (props) => (
  <Header {...props}>
    <FormattedMessage id={props.title} />
  </Header>
)
