import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

import * as Colors from '../../../utils/colors'
import SocialIcons from '../../Icons/SocialIcons'

const Box = styled.div`
  flex: 1;
  display: flex;

  @media (max-width: 992px) {
    padding-bottom: 2.5rem;
  }
`

const LeftWrapper = styled.div`
  flex: 1;
  padding-bottom: 4rem;

  @media (max-width: 992px) {
    flex: 0;
    padding-bottom: 0;
  }
`

const RightWrapper = styled.div`
  flex: 1;
  margin-left: 8rem;
  padding-top: 2rem;
  border-top: 1px solid ${Colors.GREY};

  @media (max-width: 992px) {
    margin-left: 0;
  }
`

const Title = styled.h5`
  line-height: 40px;
  font-size: 28px;
  color: ${Colors.BLACK};
`

export default () => (
  <Box>
    <LeftWrapper></LeftWrapper>

    <RightWrapper>
      <Title><FormattedMessage id="HOME.MORE_COMING_SOON" /></Title>
      <SocialIcons />
    </RightWrapper>
  </Box>
)
