import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import Breakpoint from 'react-socks'

import LabelInfo from '../LabelInfo'

const RightWrapper = styled.div`
  flex: 1;
  padding: 2rem 0 2rem 8rem;

  @media (max-width: 992px) {
    padding: 2rem 0;
  }
`

const CoinLogo = styled.img`
  width: 2.6rem;
  height: 2.6rem;
  margin-bottom: 1.75rem;
`

const InfoWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  @media (max-width: 992px) {
    flex-direction: row;
  }
`

const InfoRow = styled.div`
  flex: 1;
  display: flex;

  @media (max-width: 992px) {
    flex-direction: column;
  }
`

export default ({ logo, coin }) => (
  <RightWrapper>
    <Breakpoint large up>
      <CoinLogo src={logo} alt={`${coin.name} (${coin.key})`} />
    </Breakpoint>
    <InfoWrapper>
      <InfoRow>
        <LabelInfo
          label="HOME.OUR_COINS_ASSET_BACKING"
          info={coin.assetBacking}
        />
        <LabelInfo
          label="HOME.OUR_COINS_INITIAL_START_DATE"
          info={moment(coin.startDate).format('MMMM YYYY')}
        />
        <LabelInfo label="HOME.OUR_COINS_MARKET_CAP" info={coin.marketCap} />
      </InfoRow>

      <InfoRow>
        <LabelInfo label="HOME.OUR_COINS_VOLUME_LIQUIDITY" info={coin.volume} />
        <LabelInfo info={coin.extraInfo} />
      </InfoRow>
    </InfoWrapper>
  </RightWrapper>
)
