import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

import Container from '../../Container'
import Step from './Step'
import Step1Img from './Illustrations/step1.js'
import Step2Img from './Illustrations/step2.js'
import Step3Img from './Illustrations/step3.js'

const Wrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  padding-bottom: 6rem;

  @media (max-width: 992px) {
    padding: 0 1rem;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;

  & > * {
    margin-right: 3rem;
  }

  & > *:last-child {
    margin-right: 0;
  }

  @media (max-width: 992px) {
    flex-direction: column;

    & > * {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
`

const HeaderWrapper = styled.h2`
  padding-top: 8rem;
  padding-bottom: 4rem;
`

const BoldHeader = styled.span`
  font-size: 40px;
  line-height: 56px;
  font-weight: bold;
`

const NonBoldHeader = styled.span`
  font-size: 40px;
  line-height: 56px;
  font-weight: normal;
`

export default () => (
  <Wrapper>
    <HeaderWrapper>
      <BoldHeader>
        <FormattedMessage id="HOME.HOW_IT_WORKS" />
      </BoldHeader>
      <NonBoldHeader>
        <FormattedMessage id="HOME.3_SIMPLE_STEPS" />
      </NonBoldHeader>
    </HeaderWrapper>

    <ContentWrapper>
      <Step
        text="HOME.HOW_IT_WORKS_AMOUNTS_AND_CURRENCIES"
        illustration={<Step1Img />}
        style={{ flex: 4 }}
        step={1}
        delay={900}
      />

      <Step
        text="HOME.HOW_IT_WORKS_INFORMATION_REVIEW"
        illustration={<Step2Img />}
        style={{ flex: 5 }}
        step={2}
        delay={900}
      />

      <Step
        text="HOME.HOW_IT_WORKS_SEND_RECEIVE"
        illustration={<Step3Img />}
        style={{ flex: 4 }}
        step={3}
        delay={900}
      />
    </ContentWrapper>
  </Wrapper>
)
