import styled from 'styled-components'

import * as Colors from '../../../utils/colors'

const Background = styled.div`
  background: ${Colors.WHITE};

  @media (min-width: 992px) {
    background: linear-gradient(90deg, ${Colors.WHITE} 50%, ${Colors.LIGHT_GREY} 50%);
  }
`

export default Background
