import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl';

import * as Colors from '../../../../utils/colors'
import UnorderedList from '../../../UnorderedList'

const FeatureWrapper = styled.div`
  flex: 0 50%;

  @media (max-width: 992px) {
    margin: 0;
    width: 100%;
  }
`

const SecondWrapper = styled.div`
  border-top: 1px solid ${Colors.GREY};
  margin-left: ${props => props.marginLeft ? '4rem' : 0};
  margin-right: ${props => props.marginLeft ? 0 : '4rem'};

  @media (max-width: 992px) {
    margin: 0;
  }
`

const FeatureTitle = styled.h4`
  line-height: 56px;
  font-size: 40px;
  font-weight: bold;
  color: ${Colors.LIGHT_GREY};
  margin: 1.5rem 0;
`

const FeatureDesc = styled.span`
  line-height: 28px;
  font-size: 20px;
  color: ${Colors.GREY};
`

const StyledList = styled(UnorderedList)`
  margin: 2rem 0 6rem 0.7rem;
`

export default ({
  title,
  description,
  items,
  marginLeft = false,
  finalContent = undefined,
}) => (
  <FeatureWrapper>
    <SecondWrapper marginLeft={marginLeft}>
      <FeatureTitle><FormattedMessage id={title} /></FeatureTitle>
      <FeatureDesc><FormattedMessage id={description} /></FeatureDesc>
      <StyledList
        items={items}
        itemStyle={{ color: Colors.WHITE, fontWeight: 'bold' }}
        finalContent={finalContent}
      />
    </SecondWrapper>
  </FeatureWrapper>
)
