import React from 'react'
import styled from 'styled-components'

import * as Colors from '../../../utils/colors'
import MainHeader from '../MainHeader'

const BlackHeader = styled(MainHeader)`
  color: ${Colors.BLACK};
`

export default (props) => <BlackHeader {...props} />
