import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl';

import * as Colors from '../../utils/colors'

const FeatureBulletList = styled.ul`
  margin-left: 1rem;
  list-style-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='5' height='10' viewBox='0 0 5 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0.4 3.22H2.16V0.759999H0.4V3.22Z' fill='%2375828A'/%3e%3c/svg%3e ");

  @media (max-width: 992px) {
    margin-left: 0.75rem;
  }
`

const FeatureListItem = styled.li`
  line-height: 28px;
  font-size: 20px;
  color: ${Colors.BLACK};
  margin-bottom: 2rem;

  @media (max-width: 992px) {
    line-height: 20px;
    font-size: 16px;
    margin-bottom: 1rem;
  }
`

export default (props) => (
  <FeatureBulletList {...props}>
    { props.items.map((item, idx) => (
      <FeatureListItem key={idx} style={props.itemStyle}>
        <FormattedMessage id={item} />
        { idx === props.items.length - 1 && props.finalContent ? props.finalContent : null }
      </FeatureListItem>
    ))}
  </FeatureBulletList>
)
