import React from 'react'
import styled from 'styled-components'
import Breakpoint from 'react-socks'
import { FormattedMessage } from 'react-intl'

import * as Colors from '../../../../utils/colors'

const CoinHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const LeftWrapper = styled.div`
  flex: 1;
`

const CoinLogo = styled.img`
  width: 2.6rem;
  height: 2.6rem;
  margin-bottom: 0;
`

const CoinName = styled.h4`
  line-height: 40px;
  font-size: 28px;
  font-weight: normal;
  color: ${Colors.BLACK};
  margin: 2rem 0;
`

const CoinDescription = styled.span`
  line-height: 28px;
  font-size: 20px;
  color: ${Colors.GREY};
`

const KnowMoreLink = styled.a`
  font-weight: bold;
  text-decoration: underline;
  line-height: 28px;
  font-size: 20px;
  color: ${Colors.GREY};
`

export default ({ logo, coin }) => (
  <LeftWrapper>
    <CoinHeader>
      <CoinName>{`${coin.name} (${coin.key})`}</CoinName>
      <Breakpoint medium down>
        <CoinLogo src={logo} alt={`${coin.name} (${coin.key})`} />
      </Breakpoint>
    </CoinHeader>
    <CoinDescription>{coin.description} </CoinDescription>
    <KnowMoreLink href={coin.knowMore} target="_blank">
      <FormattedMessage id="HOME.KNOW_MORE" />
    </KnowMoreLink>
  </LeftWrapper>
)
