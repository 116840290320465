import React from 'react'
import styled from 'styled-components'
import IntersectionVisible from 'react-intersection-visible'

const Svg = styled.svg`
  &.not-visible {
    display: none;
  }

  &.visible {
    .pen-drawable {
      stroke-dasharray: 1000;
      stroke-dashoffset: 1000;
      animation: dash 4s linear forwards;
      animation-delay: 0s;
    }

    @keyframes dash {
      to {
        stroke-dashoffset: 0;
      }
    }
  }
`

export default class Icon extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      visible: false,
    }
  }

  render() {
    const { visible } = this.state
    return (
      <IntersectionVisible
        onHide={() => this.setState({ visible: false })}
        onShow={() => this.setState({ visible: true })}
      >
        <Svg
          className={visible ? 'visible' : 'not-visible'}
          width="118"
          height="116"
          viewBox="0 0 118 116"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="pen-drawable"
            d="M115 90.6667L89.3333 83.6667L82.3333 58L59 114L115 90.6667Z"
            stroke="#2775CA"
            strokeWidth="1.5"
          />
          <path
            className="pen-drawable"
            d="M89.7969 83.2004L78.5969 94.4004"
            stroke="#2775CA"
            strokeWidth="1.5"
          />
          <path
            className="pen-drawable"
            d="M3 25.3333L28.6667 32.3333L35.6667 58L59 2L3 25.3333Z"
            stroke="#F3DE8A"
            strokeWidth="1.5"
          />
          <path
            className="pen-drawable"
            d="M28.2031 32.7996L39.4031 21.5996"
            stroke="#F3DE8A"
            strokeWidth="1.5"
          />
        </Svg>
      </IntersectionVisible>
    )
  }
}
