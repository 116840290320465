import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl';

import * as Colors from '../../../../utils/colors'

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
`

const LabelText = styled.span`
  line-height: 20px;
  font-size: 16px;
  color: ${Colors.GREY};
`

const InfoText = styled.span`
  line-height: 20px;
  font-size: 16px;
  color: ${Colors.BLACK};
`

export default ({ label, info }) => (
  <Wrapper>
    { label && <LabelText><FormattedMessage id={label} /></LabelText> }
    <InfoText>{info}</InfoText>
  </Wrapper>
)
