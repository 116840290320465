import React from 'react'
import styled from 'styled-components'
import IntersectionVisible from 'react-intersection-visible'

const Svg = styled.svg`
  &.not-visible {
    display: none;
  }

  &.visible {
    .pen-drawable {
      stroke-dasharray: 1000;
      stroke-dashoffset: 1000;
      animation: dash 4s linear forwards;
      animation-delay: 0s;
    }

    @keyframes dash {
      to {
        stroke-dashoffset: 0;
      }
    }
  }
`

export default class Icon extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      visible: false,
    }
  }

  render() {
    const { visible } = this.state
    return (
      <IntersectionVisible
        onHide={() => this.setState({ visible: false })}
        onShow={() => this.setState({ visible: true })}
      >
        <Svg
          className={visible ? 'visible' : 'not-visible'}
          width="113"
          height="116"
          viewBox="0 0 113 116"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="pen-drawable"
            d="M72 101.2L83.4286 114L112 82"
            stroke="#00A9A5"
            strokeWidth="1.5"
          />
          <line
            className="pen-drawable"
            y1="1.25"
            x2="96"
            y2="1.25"
            stroke="#00A9A5"
            strokeWidth="1.5"
          />
          <line
            className="pen-drawable"
            y1="17.25"
            x2="80"
            y2="17.25"
            stroke="#00A9A5"
            strokeWidth="1.5"
          />
          <line
            className="pen-drawable"
            y1="33.25"
            x2="112"
            y2="33.25"
            stroke="#F3DE8A"
            strokeWidth="1.5"
          />
          <line
            className="pen-drawable"
            y1="49.25"
            x2="66"
            y2="49.25"
            stroke="#2775CA"
            strokeWidth="1.5"
          />
          <line
            className="pen-drawable"
            y1="65.25"
            x2="88"
            y2="65.25"
            stroke="#00A9A5"
            strokeWidth="1.5"
          />
        </Svg>
      </IntersectionVisible>
    )
  }
}
